const ListaAlunos = ({ alunos, setAlunoInfo, setModal }) => {

    const enviarBoletim = (aluno) => {
        setAlunoInfo(aluno);
        setModal(true);
    }

  return (
    <>
      <hr />
      <legend>Exibindo Alunos</legend>
      <table className="table table-bordered table-striped">
        <tr>
          <td>
            <strong>Aluno</strong>
          </td>
          <td>
            <strong>E-mail</strong>
          </td>
          <td>
            <strong>Último envio</strong>
          </td>
          <td>
            <strong></strong>
          </td>
        </tr>
        {alunos &&
          alunos.map((aluno, idx) => (
            <tr key={idx}>
              <td>{aluno.name}</td>
              <td>{aluno.email}</td>
              <td>{aluno.ultimo_boletim}</td>
              <td>
                {" "}
                <button onClick={() => enviarBoletim(aluno)} className="btn btn-success">Enviar Boletim</button>
              </td>
            </tr>
          ))}
      </table>
    </>
  );
};

export default ListaAlunos;
