import React, { useEffect, useState } from "react";

export const CompetenciaContext = React.createContext({});

export const CompetenciaProvider = (props) => {
  const [competencia, setCompetencia] = useState(0);

  useEffect(() => {
    // console.log("qual competencia agora ", competencia);
  }, [competencia])

  return (
    <CompetenciaContext.Provider value={{ competencia, setCompetencia }}>
      {props.children}
    </CompetenciaContext.Provider>
  );
};

export const useCompetencia = () => React.useContext(CompetenciaContext);
