import styled from "styled-components";

export const MainWrapper = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  color: #fff;

  .dropdown-item {
    color: #333 !important;
  }

  .bgdark {
    background-color: #007bc6;
    margin-left: -15px;

    a {
      background-color: #05367f;
      margin-bottom: 1px;
      color: #fff;
    }

    hr {
      border-top: 1px solid #3b95f2;
      margin: 10px 0px;
    }
  }

  .btn-primary {
    background-color: #05367f;
    display: block;
    border: 0px;
    width: 100%;
  }

  .card {
    margin: 15px 0px;
  }

  legend{
    small{
      font-size: 14px;
    }
  }
`;

export const MainContent = styled.div`
  background-color: #fff;
  padding: 15px;
  display: block;
  margin: 10px 0px;
  color: #333;

  .pull-right {
    position: relative;
    float: right;
  }
`;

export const H1 = styled.h1`
  text-transform: uppercase;
  font-size: 24px;
`;

export const H2 = styled.h2`
  text-transform: uppercase;
  font-size: 24px;
  color: #05367f;
  font-weight: 700;
`;
