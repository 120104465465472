const ListaTurma = ({ ano, turmas, setLoadingListaAluno, setTurmaId }) => {

    const mostrarAlunos = (turma) => {
        setLoadingListaAluno(true);
        setTurmaId(turma);
    }

  return (
    <>
      <hr />
      <legend>Exibindo turmas de {ano}</legend>
      <table className="table table-bordered table-striped">
        <tr>
          <td>
            <strong>Nome da Classe</strong>
          </td>
          <td>
            <strong>Tipo</strong>
          </td>
          <td>
            <strong></strong>
          </td>
        </tr>
        {turmas &&
          turmas.map((turma, idx) => (
            <tr key={idx}>
              <td>{turma.name}</td>
              <td>{turma.type}</td>
              <td>
                {" "}
                <button onClick={() => mostrarAlunos(turma.id)} className="btn btn-success">
                  Visualizar
                </button>
              </td>
            </tr>
          ))}
      </table>
    </>
  );
};

export default ListaTurma;
