import styled from "styled-components";

import { Newspaper } from "@styled-icons/ionicons-outline/Newspaper";
import { Home } from "@styled-icons/boxicons-regular/Home";
import { Googleclassroom } from "@styled-icons/simple-icons/Googleclassroom";
import { Paint } from "@styled-icons/boxicons-regular/Paint";
import { AddToQueue } from "@styled-icons/material/AddToQueue";
import { ChatDots } from "@styled-icons/bootstrap/ChatDots";
import { Users } from "@styled-icons/fa-solid/Users";
import { LogoutBox } from "@styled-icons/remix-line/LogoutBox";
import { PeopleSettings } from "@styled-icons/fluentui-system-filled/PeopleSettings";
import { HatGraduation } from "@styled-icons/fluentui-system-regular/HatGraduation";

export const MenuSite = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
`;

export const Azul = styled.div`
  background-color: #007bc6 !important;
  color: #fff;
  display: block;
  width: 100%;
  padding: 15px 0px;

  .list-group-item{
    background-color: transparent;
    color: #fff;
    padding: 0px 5px;
    border: 0px;

    a{
      color: #fff;
    }
  }
`;

export const Branco = styled.div`
  background-color: #fff !important;
  color: #007bc6;
  display: block;
  width: 100%;
  padding: 5px 0px;
  border-bottom: 1px solid #eee;

  .nav-link{
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
  }
`;


export const LogoWrapper = styled.div`
  padding: 40px;
  display: block;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const LogoWrapperMob = styled.div`
  img {
    max-width: 70px;
    height: auto;
  }
`;

export const AjustarBar = styled.div`
  .bg-blue {
    background-color: #007bc6 !important;

    a {
      color: #fff !important;
    }

    .dropdown-menu {
      background-color: #007bc9 !important;
    }
  }
`;

export const NewsPapperIcon = styled(Newspaper)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const HomeIcon = styled(Home)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const ClassIcon = styled(Googleclassroom)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const TemaIcon = styled(Paint)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const MaterialIcon = styled(AddToQueue)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const ChatIcon = styled(ChatDots)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const UsersIcon = styled(Users)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const LogoutIcon = styled(LogoutBox)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const SettingsIcon = styled(PeopleSettings)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const GraduationIcon = styled(HatGraduation)`
  color: #ccc;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

