import { Link, Redirect } from "react-router-dom";
import * as S from "./styled";
import { useAuth } from "../../context/auth";

import LogoTop from "../../assets/logo-topo.png";

import {
  Container,
  Row,
  Col,
  Image,
  Nav,
  Navbar,
  ListGroup,
} from "react-bootstrap";

function Menu() {
  const { user, setUser } = useAuth();

  function logout() {
    localStorage.removeItem("token");
    setUser({});
    <Redirect
      to={{
        pathname: "/",
      }}
    />;
  }

  return (
    <S.MenuSite>
      <S.Azul>
        <Container>
          <Row>
            <Col lg={5}>
              <Link to="/logado" className="nav-link">
                <Image src={LogoTop} fluid />
              </Link>
            </Col>
            <Col lg={7}>
              <ListGroup horizontal style={{ justifyContent: "flex-end" }}>
                <ListGroup.Item>
                  <Link to="/logado" className="nav-link">
                    <strong>Bem vindo(a) {user.nome}</strong>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to="/logado" className="nav-link">
                    Perfil
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link onClick={logout} to="/" className="nav-link">
                    <S.LogoutIcon /> Sair
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </S.Azul>
      <S.Branco>
        <Container>
          <Row>
            <Col xs={12}>
              <Navbar className="bg-blue" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Link to="/logado" className="nav-link">
                      <S.HomeIcon /> Home
                    </Link>

                    {user.nivel === "a" && (
                      <Link to="/boletim" className="nav-link">
                        <S.GraduationIcon /> Boletim
                      </Link>
                    )}

                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </S.Branco>
    </S.MenuSite>
  );
}

export default Menu;
