import { BrowserRouter, Route } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Boletim from "./pages/Boletim";

export const PrivateRoute = ({ component: Component, socket, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Component socket={socket} {...props} />}
  />
);

function Routes(props) {
  const { socket } = props;

  return (
    <BrowserRouter>
      <Route path="/" exact component={Login} />
      <PrivateRoute path="/logado" component={Main} />
      <PrivateRoute path="/boletim" component={Boletim} />
    </BrowserRouter>
  );
}

export default Routes;
