import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as S from "./styled";
import Template from "../../template";
import api from "../../services/api";
import ListaTurma from "../../components/Boletim/ListaTurma";
import ListaAlunos from "../../components/Boletim/ListaAlunos";

interface ITurma {
  id?: any;
  name?: String;
  type?: String;
}

interface IAluno {
  id?: number;
  name?: String;
  email?: String;
  ultimo_boletim?: String;
}

const Boletim: React.FC = () => {
  /* carregar informacoes da turma */
  const [loading, setLoading] = useState(true);
  const [ano, setAno] = useState<number>(new Date().getFullYear());
  const [turmas, setTurmas] = useState<ITurma[]>();

  /* carregar informacoes da lista de alunos */
  const [loadingListaAluno, setLoadingListaAluno] = useState(false);
  const [loadingAluno, setLoadingAluno] = useState(true);
  const [turmaId, setTurmaId] = useState(0);
  const [listaAluno, setListaAluno] = useState<IAluno[]>();

  /* carregar informacoes para envio de boletim de aluno escolhido */
  const [modal, setModal] = useState(false);
  const [alunoInfo, setAlunoInfo] = useState<IAluno>({});
  const [listaEmail, setListaEmail] = useState<string>();

  /* recupera lista de classes/turmas */
  const getClassesLista = async () => {
    const dataClasse = {
      ano: ano,
    };
    setLoading(true);

    try {
      let { data } = await api.post("/classes/lista", dataClasse);
      setTurmas(data);
    } catch (error) {
      console.log("Boletim ", error);
    }
    setLoading(false);
    setLoadingListaAluno(false);
  };

  useEffect(() => {
    getClassesLista();
  }, [ano]);

  /* recupera a lista de alunos da classe/turma escolhida */
  const getListaAlunos = async () => {
    const dataClasse = {
      turmaId: turmaId,
    };
    setLoadingAluno(true);

    try {
      let { data } = await api.post("/classes/lista/alunos", dataClasse);
      setListaAluno(data);
    } catch (error) {
      console.log("Boletim Alunos ", error);
    }
    setLoadingAluno(false);
  };

  useEffect(() => {
    if (turmaId > 0) {
      getListaAlunos();
    }
  }, [turmaId]);

  /* dispara o envio de boletim do aluno escolhido */
  const handleSendBoletim = async () => {
    const dataBoletim = {
      user_id: alunoInfo.id,
      email: alunoInfo.email,
      another_email: listaEmail,
    };

    try {
      const { data } = await api.post("/boletim/enviar", dataBoletim);
      if (data.sucesso) {
        toast.success("Boletim enviado");
        setModal(false);
        setAlunoInfo({});
        setListaEmail('');
        getListaAlunos();
      } else {
        toast.error("Algo deu errado");
      }
    } catch (error) {
      toast.error("Ocorreu um erro, não foi possível enviar o boletim");
      console.log("Boletim Envio ", error);
    }
  };

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <Row>
                  <Col>
                    <S.H2>Boletim Aluno</S.H2>
                    <small>Filtre abaixo as turmas por ano.</small>
                    <hr />
                    <select
                      onChange={(e: any) =>
                        setAno(
                          e.target.value != ""
                            ? e.target.value
                            : new Date().getFullYear()
                        )
                      }
                      className="form-control"
                    >
                      <option value="">Selecione o ano</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {loading ? (
                      <>
                        <legend>Carregando</legend>
                      </>
                    ) : (
                      <>
                        {loadingListaAluno ? (
                          <>
                            <button
                              className="btn btn-success"
                              onClick={() => setLoadingListaAluno(false)}
                            >
                              Voltar para turmas
                            </button>
                            {loadingAluno ? (
                              <>
                                <legend>Carregando Alunos</legend>
                              </>
                            ) : (
                              <ListaAlunos
                                setModal={setModal}
                                setAlunoInfo={setAlunoInfo}
                                alunos={listaAluno}
                              />
                            )}
                            <button
                              className="btn btn-success"
                              onClick={() => setLoadingListaAluno(false)}
                            >
                              Voltar para turmas
                            </button>
                          </>
                        ) : (
                          <ListaTurma
                            setTurmaId={setTurmaId}
                            setLoadingListaAluno={setLoadingListaAluno}
                            ano={ano}
                            turmas={turmas}
                          />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>

      <Modal show={modal} size="lg">
        <Modal.Header>
          <Modal.Title className="text-center" style={{ width: "100%" }}>
            Envio de boletim
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div onClick={() => {}}>
            <p>
              Você esta enviando o boletim para o aluno{" "}
              <strong>{alunoInfo.name}</strong>
              <br />
              Ele receberá o boletim no email <strong>{alunoInfo.email}</strong>
            </p>
          </div>
          <p>
            Deseja enviar o boletim para outro email além do cadastrado pelo
            aluno? Se sim preencha o campo abaixo.
          </p>
          <p>
            <input
              className="form-control"
              onChange={(e: any) => setListaEmail(e.target.value)}
              value={listaEmail}
              type="text"
              name="outroemail"
              placeholder="Digite outro email"
            />
          </p>
          <p className="text-right">
            <button onClick={handleSendBoletim} className="btn btn-primary">
              Enviar boletim
            </button>
          </p>
        </Modal.Body>
      </Modal>
    </Template>
  );
};

export default Boletim;
